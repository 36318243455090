<template>
  <div class="profile__wrapper">
    <div
      v-for="group in getModel.group"
      :key="group.name"
      class="profile__block"
    >
      <h4 class="title-h4 profile__title">
        {{ $t(`profile.${group.name}`) }}
      </h4>
      <div class="profile__grid">
        <div
          v-for="item in group.fields"
          :key="item.name"
          :class="`${item.column_type}`"
        >
          <template v-if="item.type === 'file'">
            <div class="files_upload__drop_zone__wrapper">
              <div class="files_upload__label">
                <div class="files_upload__label_text">
                  {{ $t(`main.info`) }}
                </div>
                <div class="files_upload__label_icon">
                  <svg-icon name="info_questions" />
                </div>
              </div>
              <div class="files_upload__drop_zone">
                <div class="drope_zone__icon">
                  <svg-icon name="upload_small_black" />
                </div>
                <div class="drope_zone__text">
                  {{ $t(`profile.${item.name}`) }}
                </div>
                <div class="drope_zone__icon arrow_upload">
                  <svg-icon name="arrow_upload" />
                </div>
              </div>
            </div>
          </template>
          <text-field
            v-else
            :id="item.name"
            v-model="getCurUserInfo[item.name]"
            :title="$t(`profile.${item.name}`)"
            :is-rate="item.type === 'rate'"
            :is-icon="item.type === 'text_icon'"
            is-disabled
            icon-name="actual"
          />
        </div>
      </div>
    </div>
    <Button
      :is-icon="true"
      class="print__btn"
      right-icon-name="arrow"
      @click.native="printWindow()"
      >{{ $t('buttons.print') }}
    </Button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import profile from '../../models/content_administrator/profile';

export default {
  data() {
    return {
      user: {},
    };
  },
  computed: {
    ...mapGetters(['getAuthUserData']),
    getModel() {
      return profile;
    },
    getCurUserInfo() {
      let obj = {};
      if (!!this.getAuthUserData) {
        for (let key in this.getAuthUserData) {
          let val = !!this.getAuthUserData[key]
            ? this.getAuthUserData[key]
            : '-';
          // if (key === 'star_category') {
          //   val = 0;
          // }
          obj[key] = val;
        }
      }
      return obj;
    },
  },
  created() {
    this.$store.dispatch('getAuthUserData');
  },
  methods: {
    printWindow() {
      window.print();
    },
  },
};
</script>
